:root {
  /* Variant Blue */
  --primary: #3f51b5;
  --primary-light: #4357c7;
  /* Varient Brown */
  /* --primary: #e0912a;
  --primary-light: #ee9d34; */
}
.light-font {
  color:grey !important;
}