.gradient-background {
  background: linear-gradient(110deg,#3c5883,#3cdf74,#21babc,#7d89f4);
  background-size: 240% 240%;
  animation: gradient-animation 12s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}