@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/**
  D3 Graph styles
**/
.graph-image {
  fill: white !important;
  cursor: pointer;
}

.node {
  cursor: pointer;
}

.hidden {
  display: none;
}

.glass-morph {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
